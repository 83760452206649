import forge from 'node-forge';

export const generateAesKey = () => forge.random.getBytesSync(16);

export const encryptAesKey = (aes) => {
  const publicKey = forge.pki.publicKeyFromPem(
    process.env.REACT_APP_BACKEND_PUBLIC_KEY
  );

  const encryptedKey = publicKey.encrypt(aes, 'RSA-OAEP');

  return forge.util.encode64(encryptedKey);
};

export const decryptAesKey = (encryptedAesKey) => {
  const privateKey = forge.pki.privateKeyFromPem(
    process.env.REACT_APP_FRONTEND_RSA_PRIVATE_KEY
  );

  const decodedEncryptedAesKey = forge.util.decode64(encryptedAesKey);
  return privateKey.decrypt(decodedEncryptedAesKey, 'RSA-OAEP');
};

export const encryptData = (plainText, aesKey) => {
  const cipher = forge.cipher.createCipher('AES-ECB', aesKey);
  cipher.start();

  const inputBuffer = forge.util.createBuffer(plainText, 'utf8');
  cipher.update(inputBuffer);

  cipher.finish();

  const encryptedBytes = cipher.output.getBytes();
  return forge.util.encode64(encryptedBytes);
};

export const decryptData = (encryptedBase64, aesKey) => {
  const decipher = forge.cipher.createDecipher('AES-ECB', aesKey);
  decipher.start();

  const encryptedBytes = forge.util.decode64(encryptedBase64);
  decipher.update(forge.util.createBuffer(encryptedBytes));

  const result = decipher.finish();

  return result ? decipher.output.toString() : null;
};

/**
 * Util function to encrypt payload string
 * @param {string} [payload = "{}"] optional payload - In some upload operations, there is no payload required. So adding an empty object
 */
export const encryptJsonString = (payload = '{}') => {
  if (typeof payload !== 'string') {
    throw new Error(
      'Payload to be encrypted must be a string. Use JSON.stringify in case of objects'
    );
  }
  const aesKey = generateAesKey();
  const data = encryptData(payload, aesKey);
  const encryptedKey = encryptAesKey(aesKey);
  return {
    data,
    encryptedKey,
  };
};

/**
 * Util function to decrypt response from backend
 * @param {string} encryptedData
 * @param {string} encryptedKey
 */
export const decryptResponseData = (encryptedData, encryptedKey) => {
  const decryptedKey = decryptAesKey(encryptedKey);
  const decryptedData = decryptData(encryptedData, decryptedKey);

  if (decryptedData) {
    return JSON.parse(decryptedData);
  }
  return null;
};
