/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import axios from 'axios';

import SwalToast from '../components/Common/swalToast';
import API_ENDPOINTS from '../const/ApiEndPoints';
import { decryptResponseData, encryptJsonString } from './DualEncryption';

const EXCLUDED_PATHS = [
  'logout',
  'login',
  '/token/refresh/',
  'get_details_for_sso',
];
// Flag to indicate if the token is currently being refreshed
let isRefreshing = false;
// Queue to store failed requests while the token is being refreshed
let failedQueue = [];

/**
 * Checks if a given URL path is excluded from processing.
 *
 * @param {string} url - The URL to check.
 * @return {boolean} True if the URL path is excluded, false otherwise.
 */
const isPathExcluded = (url) => {
  const pathname = new URL(url)?.pathname;
  return EXCLUDED_PATHS.some((path) => pathname?.includes(path));
};

/**
 * Processes the queued requests once the token refresh is completed.
 *
 * @param {Object} error - The error object if the token refresh failed.
 * @param {string} [token=null] - The new token if the refresh was successful.
 */
const processQueue = (error, token = null) => {
  // Iterate over the failedQueue array
  failedQueue.forEach((prom) => {
    if (error) {
      // If there was an error refreshing the token, reject the promise
      prom.reject(error);
    } else {
      // If the token refresh was successful, resolve the promise with the new token
      prom.resolve(token);
    }
  });

  // Clear the failedQueue array after processing
  failedQueue = [];
};

/**
 * Refreshes the access token by making a POST request to the refresh token endpoint.
 *
 * @param {string} refreshToken - The refresh token used to refresh the access token.
 * @return {Promise<AxiosResponse>} A Promise that resolves with the response from the refresh token endpoint.
 */
const refreshAccessToken = (refreshToken) => {
  const organisation = JSON.parse(localStorage.getItem('profile_data'));
  return axios.post(
    `${API_ENDPOINTS.MODULE_BASE_URL.AUTH + API_ENDPOINTS.AUTH.REFRESH_TOKEN}`,
    {
      refresh: refreshToken,
    },
    {
      headers: {
        org: organisation?.org,
      },
    }
  );
};

/**
 * Logs out the user by making a POST request to the logout endpoint.
 *
 * @return {Promise} A Promise that resolves with the response from the logout endpoint.
 * @throws {Error} If there is an error during the logout process.
 */
const logout = () => {
  const refreshToken = localStorage.getItem('refresh_token');
  const accessToken = localStorage.getItem('access_token');
  const userData = localStorage.getItem('user_data');
  const username = userData ? JSON.parse(userData)?.username : null;

  return axios
    .post(
      `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.LOGOUT_USER}`,
      {
        refresh_token: refreshToken,
        access_token: accessToken,
        username,
      }
    )
    .then((res) => {
      SwalToast({
        icon: 'success',
        title: 'User logged out successfully.',
      });
      return res;
    })
    .catch((err) => {
      throw err;
    })
    .finally(() => {
      // Clear the localStorage and sessionStorage, redirect to /login irrespective of logout api's result
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    });
};

/**
 * Encrypts the request parameters or data if encryption is enabled and conditions are met.
 *
 * @param {import('axios').InternalAxiosRequestConfig<any>} req - The axios request configuration object.
 * @returns {Object} - The modified axios request configuration object with encrypted data if applicable.
 */
function handleRequestEncryption(req) {
  // Check if encryption is enabled and the environment is production
  // eslint-disable-next-line no-self-compare, no-constant-condition
  if (
    process.env.REACT_APP_ENABLE_ENCRYPTION === 'true' &&
    !req?._alreadyEncrypted
  ) {
    // If request parameters exist, encrypt them
    if (req?.params) {
      const { data, encryptedKey } = encryptJsonString(
        JSON.stringify(req?.params)
      );
      // Replace the parameters with the encrypted data
      req.params = { data, encrypted_key: encryptedKey };
      // Mark the request as already encrypted
      req._alreadyEncrypted = true;
    }

    // Handle encryption for multipart/form-data content-type
    if (req?.data && req?.headers?.['Content-Type'] === 'multipart/form-data') {
      const { data, encryptedKey } = encryptJsonString(req?.data?.data);
      // Encrypt only the data part, leaving file uploads intact
      const updatedData = {
        ...req?.data,
        data,
        encrypted_key: encryptedKey,
      };
      req.data = updatedData; // Merge the encrypted data back with the other form-data fields
      req._alreadyEncrypted = true; // Mark the request as already encrypted
    }

    // Handle encryption for JSON content-type
    if (req?.data && req?.headers?.['Content-Type'] === 'application/json') {
      // Encrypt the data after converting it to a JSON string
      const { data, encryptedKey } = encryptJsonString(
        JSON.stringify(req?.data)
      );
      req.data = { data, encrypted_key: encryptedKey }; // Replace the request data with the encrypted data
      req._alreadyEncrypted = true; // Mark the request as already encrypted
    }
  }

  return req; // Return the modified request object
}

/**
 * Attaches the Authorization and organization headers to the request if applicable.
 *
 * @param {Object} req - The axios request configuration object.
 * @param {string} token - The access token to be attached to the request headers.
 * @param {Object} organization - The organization data to be attached to the request headers.
 * @returns {Object} - The modified axios request configuration object with attached headers.
 */
const attachAuthHeaders = (req, token) => {
  const ORGANIZATION = JSON.parse(localStorage.getItem('profile_data'));

  if (
    token &&
    API_ENDPOINTS.HOSTS_NEEDS_TOKEN.includes(
      new URL(req.url).host.split(':')[0]
    ) &&
    !isPathExcluded(req.url)
  ) {
    req.headers.authorization = `JWT ${token}`;
  }
  if (
    API_ENDPOINTS.HOSTS_NEEDS_TOKEN.includes(
      new URL(req.url).host.split(':')[0]
    )
  ) {
    req.headers.org = ORGANIZATION?.org;
  }

  return req;
};

/**
 * Handles token refresh errors by logging out the user and rejecting the error if it is related to the token refresh endpoint.
 *
 * @param {Object} error - The error object containing the token refresh error.
 * @return {Promise|null} A promise that rejects with the error if it is related to the token refresh endpoint, otherwise null.
 */
const handleTokenRefreshError = (error) => {
  if (
    error.config.url ===
    `${API_ENDPOINTS.MODULE_BASE_URL.AUTH + API_ENDPOINTS.AUTH.REFRESH_TOKEN}`
  ) {
    // Add a delay to allow the user to see the message before logging out
    setTimeout(() => {
      logout();
      return Promise.reject(new Error(error));
    }, 2000);
  }
  return null;
};

/**
 * Handles unauthorized errors by refreshing the access token and retrying the request.
 *
 * @param {Object} error - The error object containing the unauthorized error.
 * @return {Promise} A promise that resolves with the response of the retried request or rejects with an error.
 */
const handleUnauthorizedError = async (error) => {
  if (isRefreshing) {
    // If a token refresh is already in progress, queue the request
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    })
      .then(() => {
        // Update the request headers with the new token and retry the request
        return axios(error.config);
      })
      .catch((err) => Promise.reject(new Error(err)));
  }

  // Mark token refresh as in progress
  // eslint-disable-next-line no-param-reassign
  error.config._retry = true;
  isRefreshing = true;

  const refreshToken = localStorage.getItem('refresh_token');
  if (!refreshToken) {
    // Add a delay to allow the user to see the message before logging out
    setTimeout(() => {
      logout();
    }, 2000);
    return Promise.reject(new Error(error));
  }

  // Notify user about the token refresh process
  SwalToast({
    icon: 'info',
    title:
      'Please wait, your token might have expired. We are trying to refresh the token and resend the request.',
  });

  try {
    // Attempt to refresh the access token
    const res = await refreshAccessToken(refreshToken);
    if (res?.data?.refresh && res?.data?.access) {
      // On success, update the tokens and retry the failed requests
      SwalToast({
        icon: 'success',
        title: 'Token refreshed successfully. Resending API requests.',
      });
      localStorage.setItem('refresh_token', res?.data?.refresh);
      localStorage.setItem('access_token', res?.data?.access);

      processQueue(null, res?.data?.access);
      isRefreshing = false;
      return axios(error.config);
    }
    // Handle invalid response
    processQueue(new Error('Invalid API response'), null);
    isRefreshing = false;
    // Add a delay to allow the user to see the message before logging out
    setTimeout(() => {
      logout();
    }, 2000);
    return Promise.reject(new Error(error));
  } catch (err) {
    // Handle errors during token refresh
    SwalToast({
      icon: 'error',
      title: 'Failed to refresh the token. Invalid API response.',
    });
    processQueue(err, null);
    isRefreshing = false;
    // Add a delay to allow the user to see the message before logging out
    setTimeout(() => {
      logout();
    }, 2000);
    return Promise.reject(new Error(error));
  }
};

/**
 * Handles an error response from an API request.
 *
 * @param {Object} error - The error object from the API request.
 * @return {Promise} A promise that rejects with the error.
 */
const handleErrorResponse = async (error) => {
  const tokenRefreshError = await handleTokenRefreshError(error);
  // Handle token refresh endpoint separately to avoid recursion
  if (tokenRefreshError) {
    return tokenRefreshError;
  }
  // Handle 401 Unauthorized error
  if (error.response?.status === 401 && !error?.config?._retry) {
    return handleUnauthorizedError(error);
  }

  if (!error?.config?.suppressErrorToast) {
    let title = error?.response?.data?.msg;
    if (title) {
      title = typeof title === 'string' ? title : JSON.stringify(title);
    } else {
      title = error.message;
    }
    SwalToast({
      icon: 'error',
      title,
    });
  }

  if (typeof error?.config?.errorCallback === 'function') {
    error?.config?.errorCallback(error);
  }

  return Promise.reject(new Error(error));
};

export const initializeAxios = () => {
  const showLoaderEvent = new Event('showLoader', { bubbles: true });
  const hideLoaderEvent = new Event('hideLoader', { bubbles: true });
  let numberOfApiCallsPending = 0;
  const headersCommonOptions = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  axios.defaults.headers.common = headersCommonOptions;

  // Cleaning the interceptor handlers so our interceptor does not run two times.
  if (axios.interceptors.request.handlers.length > 0) {
    axios.interceptors.request.handlers = [];
  }
  if (axios.interceptors.response.handlers.length > 0) {
    axios.interceptors.response.handlers = [];
  }

  axios.interceptors.request.use((req) => {
    numberOfApiCallsPending += 1;

    if (
      req.loader !== false &&
      req?.url !==
        `${process.env.REACT_APP_VAULT_BASE_URL}/vault/graphplot/getnodes/`
    ) {
      document.dispatchEvent(showLoaderEvent);
    }

    // eslint-disable-next-line no-param-reassign
    req = handleRequestEncryption(req);
    const token = localStorage.getItem('access_token');
    // eslint-disable-next-line no-param-reassign
    req = attachAuthHeaders(req, token);

    return req;
  });

  axios.interceptors.response.use(
    (response) => {
      numberOfApiCallsPending -= 1;
      if (numberOfApiCallsPending === 0) {
        setTimeout(() => {
          document.dispatchEvent(hideLoaderEvent);
        }, 400);
      }

      const isJSONResponse =
        response?.headers?.['content-type'] === 'application/json';

      /**
       * Handle decryption only when:
       * 1. encryption is enabled
       * 2. response data is in JSON format
       * 3. response data contains a key "encrypted_key" - response with status code other than 200 are not encrypted by the server. Hence this check.
       *
       * Otherwise there is no need of decryption
       */
      if (
        process.env.REACT_APP_ENABLE_ENCRYPTION === 'true' &&
        isJSONResponse &&
        Object.keys(response?.data).includes('encrypted_key')
      ) {
        const encryptedData = response?.data?.data;
        const encryptedKey = response?.data?.encrypted_key;
        const decryptedData = decryptResponseData(encryptedData, encryptedKey);
        response.data = decryptedData;
      }
      return response;
    },
    async (error) => {
      numberOfApiCallsPending -= 1;
      // Hide loader if there are no more pending API calls
      if (numberOfApiCallsPending === 0) {
        setTimeout(() => {
          document.dispatchEvent(hideLoaderEvent);
        }, 400);
      }

      return handleErrorResponse(error);
    }
  );
};

export default initializeAxios;
